import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';
import cn from 'classnames'

import { SectionName, TitleSection } from '../Text/text';

import classes from './sectionHowItWorks.module.css'

const SectionHowItWorks  = ({ items }) => {
  const [itemActive, setItemActive] = useState(1)
  const [pausedInterval, setPausedInterval] = useState(false)
  const isLg = useMediaQuery({
    query: '(min-width: 1024px)'
  })

  const switchImage = useCallback(() => {
    if (itemActive < items.length) {
      setItemActive(itemActive + 1)
    } else {
      setItemActive(1)
    }
  }, [itemActive, items.length])

  const handleClickActive = (id) => {
    setItemActive(id)
    setPausedInterval(true)
  }

  useEffect(() => {
    if (isLg) {
      if (!pausedInterval) {
        const interval = setInterval(() => {
          switchImage()
        }, 3000);
        
        return () => clearInterval(interval);
      } else {
        setPausedInterval(false)
      }
    }
  }, [switchImage, pausedInterval, isLg])

  return <div className={classes.root}>
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <SectionName className={classes.sectionName}>HOW IT WORKS</SectionName>
        <TitleSection className={classes.title}>Getting started with meMR is easy. Here’s how it works.</TitleSection>

        <div className={classes.items}>
          {items.map((item) => (  
            <div className={classes.item} key={item.id} onClick={() => handleClickActive(item.id)}>
              <span
                className={
                  cn(classes.itemNumber, {
                    [classes.itemNumber__active]: itemActive === item.id && isLg
                  })
                }
              >{item.id}</span>
              <div>
                <h5>{item.title}</h5>
                <p>{item.paragraph}</p>
              </div>
              <picture>
                <img src={item.img.mobile} alt={item.title} />
              </picture>
            </div>
          ))}
        </div>
      </div>

      {isLg && (
        <div className={classes.contentImgs}>
          {items.map((item) => (
            <motion.picture
              id={item.id} key={item.id} style={{ display: `${itemActive === item.id ? 'block' : 'none'}`}}
              animate={itemActive === item.id ? "visible": "invisible"}
              transition={{
                delay: 0.3,
              }}
              variants={{
                visible: { opacity: 1 },
                invisible: { opacity: 0 }
              }} 
            >
              <img src={item.img.desktop} alt={item.alt} title={item.title} />
            </motion.picture>
          ))}
        </div>
      )}
    </div>
  </div>;
}
 
export default SectionHowItWorks ;