import Card from './Card';
import Button from '../Button/button';
import { SectionName, TitleSection } from '../Text/text';

import medicalRecords from '../../imgs/medical-records.svg'
import building from '../../imgs/building.svg'
import history from '../../imgs/history.svg'

import classes from './sectionTheProblem.module.css'

const items = [
  {
    id: 1,
    img: medicalRecords,
    title: 'There is no complete source of data or centralized database.',
    paragraph: 'Believe it or not, there is yet to be a complete digital solution for sharing medical records. HIEs and API solutions only get you 20-30% of the way there.'
  },
  {
    id: 2,
    img: building,
    title: 'Healthcare organizations are wasting time, energy, and resources.',
    paragraph: 'Due to the number of fragmented and siloed EHRs and incomplete HIEs, the burden is being placed on overworked office staff to manually chart-chase and compile extensive patient records. meMR frees up time and resources so providers, practices, and life sciences companies can do what they do best: heal.'
  },
  {
    id: 3,
    img: history,
    title: 'Patients don’t know their full health history.',
    paragraph: 'With multiple portals, app-fatigue, and numerous past providers, patients are overwhelmed with options, limiting ownership of their health records.'
  }
]

const SectionTheProblem = () => {
  return <div className={classes.root}>
    <SectionName>THE PROBLEM</SectionName>
    <TitleSection className={classes.title}>Too many gaps in medical record management</TitleSection>

    <div className={classes.cards}>
      {items.map((item) => (
        <Card key={item.id} img={item.img} title={item.title} paragraph={item.paragraph} />
      ))}
    </div>

    
    <Button type="primary" className={classes.btn}>Learn more</Button>
  </div>;
}
 
export default SectionTheProblem;