import { useState } from "react";
import Slider from "react-slick";

import shape1 from '../../imgs/testimonials-shape1.svg'
import shape2 from '../../imgs/testimonials-shape2.svg'

import arrowLeft from '../../imgs/arrow-left.svg'
import arrowRight from '../../imgs/arrow-right.svg'

import classes from './sectionTestimonials.module.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SectionTestimonials = ({ items }) => {
  const [sliderRef, setSliderRef] = useState(null)

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
         slidesToShow: 1,
        }
      }
    ],
    adaptiveHeight: true,
  };

  return <div className={classes.root}>
    <div className={classes.container}>
      <Slider {...settings} ref={setSliderRef}>
        {items.map((item) => (
          <div key={item.id}>
            <div className={classes.card} key={item.id}>
              <span className={classes.quotation__top}>‘’</span>
              <p className={classes.text}>{item.text}</p>
              <span className={classes.quotation__bottom}>‘’</span>
              <address>
                <span>{item.author}</span>
                <span>{item.infoAuthor}</span>
              </address>
            </div>
          </div>
        ))}
      </Slider>
      
      <div className={classes.buttons}>
        <button onClick={sliderRef?.slickPrev}>
          <img src={arrowLeft} alt='Arrow left Icon'/>
        </button>
        <button onClick={sliderRef?.slickNext}>
          <img src={arrowRight} alt='Arrow right Icon'/>
        </button>
      </div>
    </div>

    <img src={shape1} alt='Shape Bg' className={classes.shape1} />
    <img src={shape2} alt='Shape Bg' className={classes.shape2} />
  </div>;
}
 
export default SectionTestimonials;