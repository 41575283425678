import { motion } from 'framer-motion';

import Hero from "../../components/Hero";
import SectionWhyShouldYouUse from '../../components/SectionWhyShouldYouUse';
import Subheader from '../../components/Subheader';
import SectionHowItWorks from '../../components/SectionHowItWorks/sectionHowItWorks';

import hero1 from "../../imgs/patients-hero1.png";
import hero2 from "../../imgs/patients-hero2.png";
import shape from "../../imgs/patients-shapes.svg";

import patients1 from '../../imgs/wsyu-patients1.png'
import patients2 from '../../imgs/wsyu-patients2.svg'
import patients3 from '../../imgs/wsyu-patients3.svg'

import patientsMobile1 from '../../imgs/wsyu-patients-mobile1.svg'
import patientsMobile2 from '../../imgs/wsyu-patients-mobile2.svg'
import patientsMobile3 from '../../imgs/wsyu-patients-mobile3.svg'

import hiwDesktop1 from '../../imgs/hiw-patients-desktop1.png';
import hiwDesktop2 from '../../imgs/hiw-patients-desktop2.png';
import hiwDesktop3 from '../../imgs/hiw-patients-desktop3.png';
import hiwDesktop4 from '../../imgs/hiw-patients-desktop4.png';
import hiwDesktop5 from '../../imgs/hiw-patients-desktop5.png';

import hiwMobile1 from '../../imgs/hiw-patients-mobile1.png';
import hiwMobile2 from '../../imgs/hiw-patients-mobile2.png';
import hiwMobile3 from '../../imgs/hiw-patients-mobile3.png';
import hiwMobile4 from '../../imgs/hiw-patients-mobile4.png';
import hiwMobile5 from '../../imgs/hiw-patients-mobile5.png';

import classes from "./patientsPage.module.css"
// import SectionHelpfulData from '../../components/SectionHelpfulData/sectionHelpfulData';
import Footer from '../../components/Footer';
// import SectionTestimonials from '../../components/SectionTestimonials/sectionTestimonials';
// import SectionStartNow from '../../components/SectionStartNow/sectionStartNow';
import SectionContactUs from '../../components/SectionContactUs/sectionContactUs';

const items = [
  {
    id: 1,
    img: {
      mobile: patientsMobile1,
      desktop: patients1
    },
    title: 'A complete approach to patient data',
    paragraph: 'Our data retrieval goes several steps further than the HIEs and API solutions. We not only take on the burden of collecting any and all of the patient records you need, but also categorize, index, and structure them, before delivering them back to you on our web platform or directly through our Partner API.'
  },
  {
    id: 2,
    img: {
      mobile: patientsMobile2,
      desktop: patients2
    },
    title: 'Automate Workflows',
    paragraph: `Life sciences teams are spending a significant percentage of their time manually retrieving patient records, creating bottlenecks in the operations workflows of clinical trials. Use meMR to cut down time from patient consent to trial matching and slash operational expenses by outsourcing the chart-chasing to us.`
  },
  {
    id: 3,
    img: {
      mobile: patientsMobile3 ,
      desktop: patients3
    },
    title: 'HIPAA Compliant and Secure.',
    paragraph: `We adhere to industry and government standards, and protocols to make for a trustworthy and secure patient experience.`
  }
]

const itemsHiw = [
  {
    id: 1,
    title: 'Contact us in the form at the bottom of the page to get started.',
    paragraph: 'We’ll set up your account as an organization and go through a painless and quick onboarding process. From there, you can invite fellow team members to collaborate, add multiple trials to your account, and manage them all from your dashboard.',
    img: {
      mobile: hiwMobile1,
      desktop: hiwDesktop1
    }
  },
  {
    id: 2,
    title: 'Request Records.',
    paragraph: 'Click on “Record Retrieval Request”, enter basic patient demographic information, and upload a patient consent form.',
    img: {
      mobile: hiwMobile2,
      desktop: hiwDesktop2
    }
  },
  {
    id: 3,
    title: 'Let us do the chart chasing for you.',
    paragraph: `Sit back while we get to work on collecting, organizing, and summarizing your patient’s health history.`,
    img: {
      mobile: hiwMobile3,
      desktop: hiwDesktop3
    }
  },
  {
    id: 4,
    title: 'Reap the data rewards.',
    paragraph: `Once we’re ready, we’ll share a comprehensive look at the requested healthcare data with you on our web platform or directly into your CRM via our Partner API.`,
    img: {
      mobile: hiwMobile4,
      desktop: hiwDesktop4
    }
  },
  {
    id: 5 ,
    title: 'Add velocity to your team',
    paragraph: `Being part of meMR’s platform, you can reap the rewards of automating away the manual, frustrating work of chart chasing and focus on increasing clinical trial engagement.`,
    img: {
      mobile: hiwMobile5,
      desktop: hiwDesktop5
    }
  }
]

// const itemsTestimonials = [
//   {
//     id: 1,
//     text: 'During my battle with cancer,  I was shocked by the sheer paperwork plague. While fighting for my life, and going from specialist to specialist, I could not believe how impossible it was for me to simply get a biopsy result! I needed meMR then, so I could focus on my health.',
//     author: 'Tina',
//     infoAuthor: 'Cancer Survivor'
//   },
//   {
//     id: 2,
//     text: 'I use meMR to help me organize my history so I at least have important records for the future. Although I am healthy now, I have a ton of family history and I want to be prepared for all potential outcomes.',
//     author: 'Chris S.',
//     infoAuthor: 'Healthy, Young Professional'
//   },
//   {
//     id: 3,
//     text: 'Considering how much paperwork we have acquired just in the first 6 months of a newborn, I cannot imagine the paper trail that’ll follow him into his 18th year! I want to always remain a prepared and informed mother, especially about my baby’s health!',
//     author: 'Jaclyn D.',
//     infoAuthor: 'Mother'
//   },
//   {
//     id: 4,
//     text: 'I want to be involved in the health of my parents and loved ones, but I am busy and remote care is hard. Having all their health journies well mapped out for me during critical periods is invaluable information, and meMR allows me to remain involved and invested with care that counts.',
//     author: 'Saba S.',
//     infoAuthor: 'Caregiver'
//   },
//   {
//     id: 5,
//     text: 'Investing in properly storing health records matters, especially as a mom…it is the ultimate life insurance.',
//     author: 'Allison K.',
//     infoAuthor: 'Mom and Wife'
//   }
// ]

const getUTM = () => (new URLSearchParams(window.location.search).get("utm_source"));
let bdev, utm_source = getUTM();
utm_source === "bdev" ? bdev = true : bdev = false

const PatientsPage = () => {
  return <>
    <Hero
      sectionName="FOR LIFE SCIENCES"
      title="Gather the exact records and data you need on each patient"
      primaryBtn={{
        label: "",
        text: "Request a Demo",
        link: "#"
      }}
      secondaryBtn={{
        label: "",
        text: ""
      }}
      shape={{ 
        url: shape,
        className: classes.shape
      }}
      showImgHeroInMobile
    >
      <div className={classes.containerImgs}>
        <motion.img
          src={hero1}
          alt="App meMR"
          className={classes.img1}
          initial={{ opacity: 0.5, translateX: -20, translateY: 20, bottom: 300 }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 2,
            delay: 0.3
          }}
        />
        <motion.img
          src={hero2}
          alt="App meMR"
          className={classes.img2}
          initial={{ opacity: 0.5, translateX: 20, translateY: 20 }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 2,
            delay: 0.3
          }}
        />
        <img src={shape} alt="Shape Bg" className={classes.shape} />
      </div>
    </Hero>
    <Subheader>
      Let meMR retrieve, categorize, and normalize all of the records you need to quickly move patients from consent to trial matching.
    </Subheader>
    <SectionWhyShouldYouUse items={items} />
    <SectionHowItWorks items={itemsHiw} />
    {/* <SectionTestimonials items={itemsTestimonials} />
    <SectionHelpfulData />
    <SectionStartNow /> */}
    <SectionContactUs srcForm={bdev ? process.env.REACT_APP_JOTFORM_PATIENTS_BDEV : process.env.REACT_APP_JOTFORM_PATIENTS} />
    <Footer />
  </>;
}
 
export default PatientsPage;