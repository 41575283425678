import { Route, Routes } from 'react-router-dom';
import './App.css';

import Header from './components/Header/header';
import ScrollToTop from './helpers/scrollTop';

import MainPage from './pages/Main/mainPage';
import PatientsPage from './pages/Patients/patientsPage';
import ProvidersPage from './pages/Providers/providersPage';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/providers' element={<ProvidersPage />} />
        <Route path='/patients' element={<PatientsPage />} />
      </Routes>
    </div>
  );
}

export default App;
