import shape from '../../imgs/shape-subheader.svg'

import classes from './subheader.module.css'

const Subheader = ({children}) => {
  return <div className={classes.root}>
    <p>
      {children}
    </p>
    <img src={shape} alt="Shape Bg" />
  </div>;
}
 
export default Subheader;