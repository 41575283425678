import cn from 'classnames'

import RightIcon from '../Icons/RightIcon'

import classes from './button.module.css'

const Button = ({ type, className, size, full, withRightIcon, children }) => {
  const isAccent2Text = type === 'accent2-text'

  const rootClassName = cn(className, classes.root, {
    [classes.primary]: type === 'primary',
    [classes.accent2]: type === 'accent2',
    [classes.accent2__text]: isAccent2Text,
    [classes.other]: !type,
    [classes.sm]: size === "sm",
    [classes.withRightIcon]: withRightIcon,
    'wFull': full
  })

  return <button className={rootClassName}>
    {children}
    {withRightIcon && <RightIcon color={isAccent2Text ? 'accent2': ""} />}
  </button>; 
}
 
export default Button;