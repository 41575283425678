import classes from './card.module.css'

const Card = ({ img, title, paragraph, borderLeft }) => {
  const rootClassName = borderLeft ? classes.root__borderLeft : classes.root
  
  return <div className={rootClassName}>
    <picture>
      <source media="(max-width: 1023px)" srcSet={img.mobile} />
      <source media="(min-width: 1024px)" srcSet={img.desktop} />
      <img src={img.desktop} alt={title} />
    </picture>
    <div className={classes.containerTexts}>
      <h5>{title}</h5>
      <p>{paragraph}</p>
    </div>
  </div>;
} 
 
export default Card;