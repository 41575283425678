export const socialMedia = {
  facebook: '',
  twitter: '',
  instagram: ''
}

export const urlPath = {
  providers: '/providers',
  lifesciences: '/patients',
  aboutUs: '',
}