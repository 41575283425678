import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Logo from '../../components/Icons/Logo'
import HamburguerMenu from './HamburguerMenu'
import Button from '../Button/button'

import HamburguerIcon from '../Icons/HamburguerIcon'

import { urlPath } from '../../data.js'

import classes from './header.module.css'

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isMd = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const handleShow = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  const handleClose = () => {
    setIsOpenMenu(false);
  }

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  return <header className={scroll ? classes.root + " " + classes.scroll : classes.root}>
    <div className={classes.container}>
      <Link to="/" className={classes.logo}>
        <Logo />
      </Link>

      {isMd ? <>
        <div className={classes.links}>
          <Link to={urlPath.providers} className={classes.link}>
            For Providers
          </Link>
          <Link to={urlPath.lifesciences} className={classes.link}>
            For Life Sciences
          </Link>
          {/* <Link to="/" className={classes.link}>
            About Us
          </Link> 
          <Link to="/" className={classes.link}>
            Contact Us 
          </Link> */}
        </div>
        
        <div className={classes.buttons}>
          <a href="https://app.memrhealth.com">
            <Button size="sm">Login</Button>
          </a>
          {/* <a href="https://app.memrhealth.com/sign-up">
            <Button type="primary" size="sm">Sign up</Button>
          </a> */}
        </div>
      </> : <>
        <HamburguerIcon
          className={classes.hamburguerIcon}
          onClick={handleShow}
          open={!isOpenMenu}
        />
        <HamburguerMenu open={isOpenMenu} handleClose={handleClose} />
      </>}
    </div>
  </header>;
}
 
export default Header;