import { useRef, useState } from 'react'

import classes from './accordion.module.css'

const Accordion = ({ title, paragraph, open = false }) => {
  const [isopen, setIsopen] = useState(open)
  const contentRef = useRef(null)

  const handleToggleOpen = () => {
    setIsopen(!isopen)
  }

  return (
    <div className={classes.root} onClick={handleToggleOpen}>
      <button className={classes.accordion}>
        <span>{title}</span>
        <span>{isopen ? "-" : "+"}</span>
      </button>
      <p className={classes.content} data-open={isopen} ref={contentRef} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
    </div>
  )
} 
 
export default Accordion;