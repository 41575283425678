import { useMediaQuery } from 'react-responsive';

// import Button from '../Button/button';
import { SectionName, TitleSection } from '../Text/text';

import mobileImg from '../../imgs/oursolution-mobile.png';
import tabletImg from '../../imgs/oursolution-tablet.png';
import desktopImg from '../../imgs/oursolution-desktop.png';
import shapeDesktop1 from '../../imgs/oursolution-desktop-shape1.svg';
import shapeDesktop2 from '../../imgs/oursolution-desktop-shape2.svg';
import shapeMobile1 from '../../imgs/oursolution-mobile-shape1.svg';
import shapeMobile2 from '../../imgs/oursolution-mobile-shape2.svg';
import shapeTablet from '../../imgs/oursolution-tablet-shape.svg';

import classes from './sectionOurSolution.module.css'

const SectionTheProblem = () => {
  const isSm = useMediaQuery({
    query: '(min-width: 640px)'
  })

  const isLg = useMediaQuery({
    query: '(min-width: 1024px)'
  })

  return <div className={classes.root}>
    <div className={classes.container}>
      <div className={classes.textsContainer}>
        <SectionName className={classes.sectionName}>OUR SOLUTION</SectionName>
        <TitleSection className={classes.title}>A full-service solution for comprehensive health data.</TitleSection>

        <p className={classes.paragraph}>meMR Health makes medical record retrieval faster and more efficient for clinical operations staff across all healthcare verticals. We’re in your corner.</p>
        <p className={classes.paragraph}>Our platform bridges the gap between previous providers and current networks. Let meMR do the chart chasing, so you don’t have to!</p>
      </div>

      <div className={classes.imgContainer}>
        <picture className={classes.img}>
          <source media="(max-width: 639px)" srcSet={mobileImg} />
          <source media="(max-width: 1023px)" srcSet={tabletImg} />
          <source media="(min-width: 1024px)" srcSet={desktopImg} />
          <img src={desktopImg} alt="Preview meMR App in Cellphones" />
        </picture>
        {isLg && <>
          <img src={shapeDesktop1} alt="Shape Bg" className={classes.shapeDesktop1} />
          <img src={shapeDesktop2} alt="Shape Bg" className={classes.shapeDesktop2} />
        </>}
      </div>

      {/* <div className={classes.btnContainer}>
        <Button type="accent2" withRightIcon className={classes.btn}>Learn more</Button>
      </div> */}

      {!isLg && <>
        <img src={shapeMobile1} alt="Shape Bg" className={classes.shapeMobile1} />
        <img src={isSm ? shapeTablet : shapeMobile2} alt="Shape Bg" className={classes.shapeMobile2} />
      </>}
    </div>
  </div>;
}
 
export default SectionTheProblem;