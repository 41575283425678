import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';

import circle from '../../imgs/circle.svg';
import heart from '../../imgs/heart.svg';
import shapeDesktop1 from '../../imgs/shape-desktop1.svg';
import shapeDesktop2 from '../../imgs/shape-desktop2.svg';
import mobileImg from '../../imgs/mobile.png';
import desktopImg from '../../imgs/desktop.png';

import Button from '../Button/button';
import classes from './hero.module.css';

const Hero = () => {
  const isMd = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return <section className={classes.root}>
    <div className={classes.container}>
      <div>
        <motion.h1
          className={classes.title}
          initial={{ translateY: 50 }}
          animate={{ translateY: 0 }}
          transition={{ duration: 0.8 }} 
        >
          AI-automated medical record retrieval & analysis.
        </motion.h1>

        <motion.div
          className={classes.containerBtns}
          initial={{ translateY: 30 }}
          animate={{ translateY: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className={classes.containerBtn}>
            {/* <span>FOR PROVIDERS</span> */}
              <div onClick={() => window.scrollTo(0, document.body.scrollHeight - 1400)}>
                <Button
                  type="primary"
                  full
                  className={classes.btn}
                >
                  Request a demo
                </Button>
              </div>
          </div>

          {/* <div className={classes.containerBtn}>
            <span>FOR PATIENTS</span>
            <a href="https://apps.apple.com/us/app/memr-health-app/id1551645336">
              <Button
                full
                className={classes.btn}
              >
                Download the app
              </Button>
            </a>
          </div> */}
        </motion.div>

        {isMd ? <>
          <img src={shapeDesktop1} alt="Shape Bg" className={classes.shapeDesktop1} />
          <img src={shapeDesktop2} alt="Shape Bg" className={classes.shapeDesktop2} />
        </> : <>
          <img src={circle} alt="Circle Bg" className={classes.circle} />
          <img src={heart} alt="Heart Bg" className={classes.heart} />
        </>}
      </div>

      <div className={classes.imgsContainer}>
        <motion.img
          src={mobileImg}
          alt='MeMR App Mobile'
          className={classes.mobileImg}
          initial={{ opacity: 0.5, translateX: -20, translateY: 20 }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 2,
            delay: 0.3
          }}
        />
        <motion.img
          src={desktopImg}
          alt='MeMR App Desktop'
          className={classes.desktopImg}
          initial={{ opacity: 0.5, translateX: 20, translateY: 20 }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 2,
            delay: 0.3
          }}
        />
      </div>
    </div>
  </section>;
}
 
export default Hero;