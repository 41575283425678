import SectionTheProblem from "../../components/SectionTheProblem";
import SectionOurSolution from "../../components/SectionOurSolution";
import Subheader from "../../components/Subheader";
import SectionWhy from "../../components/SectionWhy/sectionWhy";
import SectionHowCanWeHelp from "../../components/SectionHowCanWeHelp/sectionHowCanWeHelp";
import SectionContactUs from '../../components/SectionContactUs/sectionContactUs';
import Footer from "../../components/Footer";

import Hero from "../../components/HeroHome";

const getUTM = () => (new URLSearchParams(window.location.search).get("utm_source"));
let bdev, utm_source = getUTM();
utm_source === "bdev" ? bdev = true : bdev = false

const MainPage = () => {
  return <>
    <Hero /> 
    <Subheader>
      meMR Health is a fully automated, full-service record retrieval platform that auto-categorizes & transforms records into high-yield data.
    </Subheader>
    <SectionTheProblem />
    <SectionOurSolution />
    <SectionWhy />
    <SectionHowCanWeHelp />
    <SectionContactUs srcForm={bdev ? process.env.REACT_APP_JOTFORM_GENERAL_BDEV : process.env.REACT_APP_JOTFORM_GENERAL} />
    <Footer />
  </>;
}
 
export default MainPage;