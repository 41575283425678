import * as React from "react"

const RightIcon = (props) => {
  const fill = props.color === "accent2" ? "#FDCD5D" : "#363E45"

  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m8 2.667-.94.94 3.72 3.726H2.667v1.334h8.113l-3.72 3.726.94.94L13.333 8 8 2.667Z"
        fill={fill}
      />
    </svg>
  )
}

export default RightIcon
