import Accordion from './Accordion';

import shape1 from '../../imgs/why-shape1.svg';
import shape2 from '../../imgs/why-shape2.svg';

import classes from './sectionWhy.module.css'

const items = [
  {
    id: 1,
    title: 'Why now?',
    paragraph: 'Today’s medical landscape is unlike anything we’ve seen before;  patients and providers are craving more transparency, and the government is listening – making significant regulatory changes to allow real access.'
  },
  {
    id: 2,
    title: 'Why us?',
    paragraph: `<b>We’re not an API.</b> While APIs are a good step, their main source of data  is the health information exchange, which represents < 50% of patient data. We take things a step further, with a comprehensive approach that collects 90% of patients data leaving fewer gaps and no guesswork. 
    <br><br><b>We’re not an EMR.</b> 89.9% of physicians in the US use one of the 500 EMR vendors in the market. That’s a lot of data stuck in different digital file cabinets. We’re standardizing healthcare data by removing those archaic hurdles and putting healthcare records in the hands of those who need them.
    <br><br><b>We are advocates for transparency.</b> From patient care to clinical trial matching and risk adjustment, we believe in a world where providers and healthcare organizations are empowered and informed in their decision-making and have a clear and full picture of their patient’s digital health history, all in one place. 
    `
  }
]

const SectionWhy = () => {
  return <div className={classes.root}>
    <div className={classes.container}>
      <div className={classes.accordions}>
        {items.map((item) => (
          <Accordion key={item.id} img={item.img} title={item.title} paragraph={item.paragraph} open={item.id === 1} />
        ))}
      </div>
    </div>
    <img src={shape1} alt="Shape Bg" className={classes.shape1} />
    <img src={shape2} alt="Shape Bg" className={classes.shape2} />
  </div>;
}
 
export default SectionWhy;