import { motion } from "framer-motion"

const variants1 = {
  open: {
    rotate: 0,
    translateY: 0,
    scale: 1
  },
  closed: {
    rotate: 45,
    translateY: 6,
    scale: 0.9
  }
}

const variants2 = {
  open: {
    rotate: 0,
    translateY: 0,
    scale: 1
  },
  closed: {
    rotate: -45,
    translateY: -5,
    scale: 0.9
  }
}

const HamburguerIcon = (props) => (
  <motion.svg
    width={22}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <motion.rect
      width={22} height={5.053} rx={2.526} fill="#6A6C8C"
      animate={props.open ? "open": "closed"}
      variants={variants1}
    />
    <motion.rect
      y={10.947}
      width={22} height={5.053} rx={2.526} fill="#6A6C8C"
      animate={props.open ? "open": "closed"}
      variants={variants2}
    />
  </motion.svg>
)

export default HamburguerIcon
