import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion';

import cn from 'classnames';

import shapeDesktop1 from '../../imgs/shape-desktop1.svg';

import Button from '../Button/button';
import classes from './hero.module.css'

const Hero = (props) => {
  const {
    sectionName,
    title,
    primaryBtn,
    secondaryBtn,
    isHome,
    children
  } = props;
  const isMd = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const classContainerTexts = cn(classes.containerTexts, {
    [classes.containerTexts__others]: !isHome
  })

  return <section className={classes.root}>
    <div className={classes.container}>
      <div className={classes.containerInfo}>
        <motion.div
          className={classContainerTexts}
          initial={{ translateY: 50 }}
          animate={{ translateY: 0 }}
          transition={{ duration: 0.8 }} 
        >
          <h2>{sectionName}</h2>
          <h1>{title}</h1>
        </motion.div>

        {isMd && <>
          <img src={shapeDesktop1} alt="Shape Bg" className={classes.shapeDesktop1} />
        </>}
      </div>

      <motion.div
        className={classes.containerBtns}
        initial={{ translateY: 30 }}
        animate={{ translateY: 0 }}
        transition={{ duration: 0.8 }}
      >
        {primaryBtn.text && 
          <div className={classes.containerBtn}>
            <a href={primaryBtn.link} onClick={primaryBtn.onclick}>
              <Button
                type="primary"
                full
                className={classes.btn}
              >
                {primaryBtn.text}
              </Button>
            </a>
          </div>
        }

        {secondaryBtn.text && 
          <div className={classes.containerBtn}>
            <a href={secondaryBtn.link} onClick={secondaryBtn.onclick}>
              <Button
                full
                className={classes.btn}
              >
                {secondaryBtn.text}
              </Button>
            </a>
          </div>
        }
      </motion.div>
      
      <div className={classes.containerImgs}>
        {children}
      </div>

    </div>
  </section>;
}
 
export default Hero;