import { SectionName, TitleSection } from '../Text/text';
import Card from './Card/card';

import patientsIcon from '../../imgs/hchy-patients.svg';
import providersIcon from '../../imgs/hchy-providers.svg';
import mobileShape1 from '../../imgs/hchy-mobile-shape1.svg'
import mobileShape2 from '../../imgs/hchy-mobile-shape2.svg'

import classes from './sectionHowCanWeHelp.module.css'

const cards = [
  {
    id: 1,
    img: patientsIcon,
    title: 'LifeSciences',
    paragraph: 'We make medical record retrieval fast and efficient.',
    click: () => window.scrollTo(0, document.body.scrollHeight - 1400),
    textButton: 'Contact us today'
  }, {
    id: 2,
    img: providersIcon,
    title: 'Providers',
    paragraph: 'Let us do the chart chasing, so you don’t have to.',
    click: () => window.scrollTo(0, document.body.scrollHeight - 1400),
    textButton: 'Let’s hop on a call'
  }
]

const SectionHowCanWeHelp = () => {

  return <div className={classes.root}>
    <div className={classes.container}>
      <div>
        <SectionName className={classes.sectionName}>HOW CAN WE HELP?</SectionName>
        <TitleSection className={classes.title}>Ready to begin?  Let’s do this.</TitleSection>
      </div>
      
      <div className={classes.cards}>
        {cards.map((item) => (
          <Card link={item.link} click={item.click} key={item.id} img={item.img} title={item.title} paragraph={item.paragraph} textButton={item.textButton} />
        ))}
      </div>
    </div>
    <img src={mobileShape1} alt="Shape Bg" className={classes.mobileShape1} />
    <img src={mobileShape2} alt="Shape Bg" className={classes.mobileShape2} />
  </div>;
}
 
export default SectionHowCanWeHelp;