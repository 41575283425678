import JotformEmbed from 'react-jotform-embed';

import { SectionName, TitleSection } from '../Text/text';

import classes from './sectionContactUs.module.css';

const SectionContactUs = ({ srcForm }) => {
  return <div className={classes.root}>
    <div id="contactSection" className={classes.container}> 
      <TitleSection className={classes.title}>Contact Us</TitleSection>
      <SectionName className={classes.sectionName}>We would love to hear from you!</SectionName>
      <JotformEmbed src={ srcForm }/>
    </div>
  </div>
};
 
export default SectionContactUs;