import Card from './Card';
import { TitleSection } from '../Text/text';

import classes from './sectionWhyShouldYouUse.module.css'

const SectionTheProblem = ({ items }) => {
  return <div className={classes.root}>
    <TitleSection className={classes.title}>Why should you use meMR?</TitleSection>

    <div className={classes.cards}>
      {items.map((item, index) => (
        <Card
          id={index}
          data-id={index}
          key={item.id}
          title={item.title}
          paragraph={item.paragraph}
          img={{
            mobile: item.img.mobile,
            desktop: item.img.desktop
          }}
          borderLeft={(index % 2) !== 0}
        />
      ))}
    </div>
  </div>;
}
 
export default SectionTheProblem;