import { useRef } from 'react';

import { motion } from 'framer-motion';

import Hero from "../../components/Hero";
import Subheader from '../../components/Subheader';
import SectionWhyShouldYouUse from '../../components/SectionWhyShouldYouUse';
import SectionHowItWorks from '../../components/SectionHowItWorks/sectionHowItWorks';

import hero1 from "../../imgs/providers-hero1.png";
import hero2 from "../../imgs/providers-hero2.png";
import shape from "../../imgs/providers-shapes.svg";

import saveTimeMobile from '../../imgs/wsyu-save-time-mobile.svg'
import saveTime from '../../imgs/wsyu-save-time.svg'
import unlockRenueve from '../../imgs/wsyu-unlock-revenue.svg'
import unlockRenueveMobile from '../../imgs/wsyu-unlock-revenue-mobile.svg'

import hiwMobile1 from '../../imgs/hiw-providers-mobile1.png';
import hiwMobile2 from '../../imgs/hiw-providers-mobile2.png';
import hiwMobile3 from '../../imgs/hiw-providers-mobile3.png';
import hiwMobile4 from '../../imgs/hiw-providers-mobile4.png';

import hiwDesktop1 from '../../imgs/hiw-providers-desktop1.png';
import hiwDesktop2 from '../../imgs/hiw-providers-desktop2.png';
import hiwDesktop3 from '../../imgs/hiw-providers-desktop3.png';
import hiwDesktop4 from '../../imgs/hiw-providers-desktop4.png';

import classes from "./providersPage.module.css"
import SectionTestimonials from '../../components/SectionTestimonials/sectionTestimonials';
import SectionContactUs from '../../components/SectionContactUs/sectionContactUs';
import Footer from '../../components/Footer';

const items = [
  {
    id: 1,
    img: {
      mobile: saveTimeMobile,
      desktop: saveTime
    },
    title: 'Save Time & Resources.',
    paragraph: 'Chart-chasing and analyzing patient data eats up valuable time and resources. In fact, an average of 20% of your staff’s time is wasted each day on this antiquated workflow. meMR takes the time-consuming task of tracking down medical records off your plate and improves the process for everyone involved.'
  },
  {
    id: 2,
    img: {
      mobile: unlockRenueveMobile,
      desktop: unlockRenueve
    },
    title: 'Unlock Revenue and Lower Costs.',
    paragraph: `Increase insurance reimbursements and performance scores (which can lead to higher medicare reimbursement rates) for a win-win. Plus, receive full scope of data to fully adjust for risk and capture the most comprehensive RAF score possible so you don't leave any money on the table in VBC contracts.`
  }
]

const itemsHiw = [
  {
    id: 1,
    title: 'Contact us in the form at the bottom of the page to get started as a provider or an organization.',
    paragraph: 'We will set up your account and go through a painless and quick onboarding process.',
    img: {
      mobile: hiwMobile1,
      desktop: hiwDesktop1
    }
  },
  {
    id: 2,
    title: 'Request Patient Records',
    paragraph: 'Now that you’re in the system, start compiling patient records. Simply click on “Record Retrieval Request” and input the information needed for us to get started.',
    img: {
      mobile: hiwMobile2,
      desktop: hiwDesktop2
    }
  },
  {
    id: 3,
    title: 'Let us do the chart chasing for you.',
    paragraph: `After the patient provides authorization, we get to work on aggregating, organizing, and creating a seamless digital health timeline of your patient. We are a completely secure and HIPAA compliant platform.`,
    img: {
      mobile: hiwMobile3,
      desktop: hiwDesktop3
    }
  },
  {
    id: 4,
    title: 'Reap the data rewards.',
    paragraph: 'Once the patient data is ready, we’ll share a structured view of the requested healthcare data with you via our web platform or our Partner API.',
    img: {
      mobile: hiwMobile4,
      desktop: hiwDesktop4
    }
  }
]

const itemsTestimonials = [
  {
    id: 1,
    text: 'This gap in knowledge and the glacial pace of gathering information can have life or death consequences. Oncology is largely based on second opinions and constant information exchange, we need meMR to smoothen this process',
    author: 'Dr. Bhuta',
    infoAuthor: 'Pediatric Oncologist, Brown University'
  },
  {
    id: 2,
    text: 'If patients and providers stopped hitting the rate limiting step of record retrieval, we could all be better providers and actually effectively diagnose, manage & treat our patients.',
    author: 'Dr. Adhiyaru',
    infoAuthor: 'Emergency Medicine Physician'
  },
  {
    id: 3,
    text: 'meMR health is so required  and I am excited about the solution they’re bringing into the space. Working frontline during COVID has made us all aware of the fact that our past dictates our future, especially in healthcare.',
    author: 'Dr. Neyra',
    infoAuthor: 'Infectious Disease Specialist'
  },
  {
    id: 4,
    text: 'We absolutely love meMR’s contribution to our practice, especially with patient onboarding. We used to spend 5-10 hours per week doing this scut work, which has been cut to less than 1. My office is working more efficiently and I feel like I am closing those loops and gaps in care.',
    author: 'Dr. Shital Mehta',
    infoAuthor: 'PCP, Tampa, Fl'
  }
]

const getUTM = () => (new URLSearchParams(window.location.search).get("utm_source"));
let bdev, utm_source = getUTM();
utm_source === "bdev" ? bdev = true : bdev = false

const ProvidersPage = () => {
  let providerContactForm = useRef()
  return <>
    <Hero
      sectionName="FOR PROVIDERS"
      title="Medical record retrieval solutions for providers, made by providers."
      primaryBtn={{
        label: "",
        text: "Request a demo",
        onclick: () => providerContactForm.current.scrollIntoView()
      }}
      secondaryBtn={{
        label: "",
        text: "",
        link: ""
      }}
      shape={{ 
        url: shape,
        className: classes.shape
      }}
      showImgHeroInMobile
    >
      <div className={classes.containerImgs}>
        <motion.img
          src={hero1}
          alt="App Web meMR"
          className={classes.img1}
          initial={{ opacity: 0.5, translateX: -20, translateY: 20, bottom: 300 }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 2,
            delay: 0.3
          }}
        />
        <motion.img
          src={hero2}
          alt="App Web meMR"
          className={classes.img2}
          initial={{ opacity: 0.5, translateX: '-50%', translateY: 20 }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 2,
            delay: 0.3
          }}
        />
        <img src={shape} alt="Shape Bg" className={classes.shape} />
      </div>
    </Hero>
    <Subheader>
      meMR Health is revolutionizing antiquated record retrieval workflows by automating and delivering actionable data designed to improve outcomes and lower costs.
    </Subheader>
    <SectionWhyShouldYouUse items={items} />
    <SectionHowItWorks items={itemsHiw} />
    <SectionTestimonials items={itemsTestimonials} /> 
    <div ref={providerContactForm}></div>
    <SectionContactUs srcForm={bdev ? process.env.REACT_APP_JOTFORM_PROVIDERS_BDEV : process.env.REACT_APP_JOTFORM_PROVIDERS} />
    <Footer />
  </>;
}
 
export default ProvidersPage;