import cn from 'classnames'

import classes from './text.module.css'

export const TitleSection = ({ className, children }) => {
  const classNames = cn(classes.title, className)
  return (
    <h2 className={classNames}>{ children }</h2> 
  )
}

export const SectionName = ({ className, children }) => {
  const classNames = cn(classes.sectionName, className)
  return (
    <span className={classNames}>{ children }</span>
  )
}