import { Link } from 'react-router-dom';

import logo from '../../imgs/logo-footer.svg'
import facebook from '../../imgs/facebook.svg'
import twitter from '../../imgs/twitter.svg'
import instagram from '../../imgs/instagram.svg'

import { urlPath } from '../../data.js'

import classes from './footer.module.css'

const Footer = () => {
  let year = new Date().getFullYear()
  return <footer>
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.info}>
          <img src={logo} alt="Logo meMR" />
          <span>meMR prioritizes secure and privacy-first data exchanges. We are a fully HIPAA compliant platform using the Advanced Encryption Standard (AES) to shield all our patient health information.</span>
        </div>

        <div className={classes.links}>
          <Link to={urlPath.providers} className={classes.link}>
            For Providers
          </Link>
          <Link to={urlPath.lifesciences} className={classes.link}>
            For Life Sciences
          </Link>
          {/* <Link to="/" className={classes.link}>
            About Us
          </Link>
          <Link to="/" className={classes.link}>
            Contact Us 
          </Link> */}
        </div>

        <div className={classes.social}>
          <a href={process.env.REACT_APP_SOCIAL_FACEBOOK} target="_blank" rel='noreferrer'>
            <img src={facebook} alt="Facebook meMR Health" />
          </a>
          <a href={process.env.REACT_APP_SOCIAL_TWITTER} target="_blank" rel='noreferrer'>
            <img src={twitter} alt="Twitter meMR Health" />
          </a>
          <a href={process.env.REACT_APP_SOCIAL_INSTAGRAM} target="_blank" rel='noreferrer'>
            <img src={instagram} alt="Instagram meMR Health" />
          </a>
        </div>
      </div>
    </div>

    <div className={classes.rootSubfooter}>          
      <div className={classes.subfooter}>       
        <div className={classes.terms}>
          <a href='/terms-use-privacy-policy.pdf' target="_blank">Terms of use &amp; Privacy Policy</a> 
        </div>
        <span>&copy; Copyright { year } MEMR, Inc. All Rights Reserved.</span>
      </div>
    </div>
  </footer>;
}
 
export default Footer;