import cn from 'classnames'
import { Link } from 'react-router-dom'
import Logo from '../../../components/Icons/Logo'
import Button from '../../Button/button'

import { urlPath } from '../../../data.js'

import classes from './hamburguerMenu.module.css'

const HamburguerMenu = ({ open, handleClose }) => {
  const rootClassName = cn(classes.root, {
    [classes.root__open]: open
  })

  return <div className={rootClassName}>
    <Link to="/" className={classes.logo} onClick={handleClose}>
      <Logo />
    </Link>

    <div>
      <Link to={urlPath.providers} className={classes.link} onClick={handleClose}>
        For Providers
      </Link>
      <Link to={urlPath.lifesciences} className={classes.link} onClick={handleClose}>
        For Life Sciences
      </Link>
      {/* <Link to="/" className={classes.link} onClick={handleClose}>
        About Us
      </Link>
      <Link to="/" className={classes.link} onClick={handleClose}>
        Contact Us
      </Link> */}
    </div>

    <div>
      {/* <a href="https://app.memrhealth.com/sign-up">
        <Button type="primary" full className={classes.btn}>Sign up</Button>
      </a> */}

      <div className={classes.btnLoginContainer}>
        <span>Already have an account?</span>
        <a href="https://app.memrhealth.com/">
          <Button full className={classes.btn}>Log in</Button>
        </a>
      </div>
    </div>
  </div>
}

export default HamburguerMenu;