import classes from './card.module.css'

const Card = ({ img, title, paragraph }) => {
  return <div className={classes.root}>
    <img src={img} alt={title} />
    <h5>{title}</h5>
    <p>{paragraph}</p>
  </div>;
} 
 
export default Card;