import Button from '../../Button/button';
import classes from './card.module.css'

const Card = ({ img, title, paragraph, click, textButton }) => {
  return <div className={classes.root}>
    <div className={classes.title}>
      <img src={img} alt={title} />
      <h5>{title}</h5>
    </div>
    <p>{paragraph}</p>
    <span onClick={click}><Button type="accent2-text" withRightIcon size="sm" className={classes.btn}>{textButton}</Button></span>
  </div>;
} 
 
export default Card;